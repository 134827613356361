import { Component, Input } from "@angular/core";

@Component({
  selector: "app-avatar",
  standalone: true,
  templateUrl: "./avatar.component.html"
})
export class AvatarComponent {
  @Input() imageUrl: string;
}
